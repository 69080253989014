import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layouts/layout.dk"
import Image from "../components/image"
import SEO from "../components/seo"
import ImageGallery from "../components/features/ImageGallery.jsx"

import MainFeature from "../components/features/MainFeature.jsx"
import { useGalleryImage } from "../components/hooks/useGalleryImage"
import gallerie05h from "../images/newset01/DSC03313-h.png"

const gallerie01 = "../images/newset01/Cafe-front.png"
const gallerie02 = "../images/newset01/DSC03294.png"
const gallerie03 = "../images/newset01/DSC03297.png"
const gallerie04 = "../images/newset01/DSC03300.png"
const gallerie05 = "../images/newset01/DSC03313.png"
const gallerie06 = "../images/newset01/DSC03314.png"
const gallerie07 = "../images/newset01/DSC03322.png"
const gallerie08 = "../images/newset01/DSC03325.png"
const gallerie09 = "../images/newset01/DSC03333.png"
const gallerie10 = "../images/newset01/DSC03336.png"
const gallerie11 = "../images/newset01/DSC03338.png"
const gallerie12 = "../images/newset01/DSC03344.png"
const gallerie13 = "../images/newset01/DSC03345.png"
const gallerie14 = "../images/newset01/DSC03348.png"
const gallerie15 = "../images/newset01/DSC03352.png"
const gallerie16 = "../images/newset01/DSC03364.png"
const gallerie17 = "../images/newset01/DSC03366.png"
const gallerie18 = "../images/newset01/DSC03373.png"
const gallerie19 = "../images/newset01/DSC03375.png"
const gallerie20 = "../images/newset01/DSC03389.png"
const gallerie21 = "../images/newset01/DSC03391.png"
const gallerie22 = "../images/newset01/DSC03394.png"
const gallerie23 = "../images/newset01/DSC03396.png"
const gallerie24 = "../images/newset01/DSC03398.png"
const gallerie25 = "../images/newset01/DSC03400.png"
const gallerie26 = "../images/newset01/DSC03403.png"
const gallerie27 = "../images/newset01/DSC03414.png"

const IndexPage = props => {
  const topicImage = useGalleryImage()
  const backgroundFluidImageStack = [
    topicImage.childImageSharp.fluid,
    `linear-gradient(rgba(17, 17, 15, 0.33), rgba(47, 17, 17, 0.53))`,
  ].reverse()

  const pageData = {
    pageTitle: "DE SMUKKESTE INDTRYK",
    subTitle: "Wir lieben Kuchen",
    sectionTitle: "Wir lieben Kuchen...",
    seoTitle:
      "Impressionen aus unserem Cafe: Kuchen, Cafe, Frühstück den ganzen Tag",
  }

  const formConfig = {
    id: "tina-tutorial-index",
    label: "Edit Page",
    fields: [
      {
        name: "pageTitle",
        label: "Page Title",
        component: "text",
      },
      {
        name: "subTitle",
        label: "Subtitle",
        component: "text",
      },
      {
        name: "seoTitle",
        label: "SEO Title",
        component: "text",
      },
    ],
    initialValues: pageData,
    onSubmit: async () => {
      window.alert("Saved!")
    },
  }

  const GalleryImage = styled.div`
    ${props =>
      css`
        background-image: url("${props.imageSrc}");
      `}
    ${tw`h-full w-full bg-cover rounded-sm shadow-md`}
  `

  return (
    <Layout
      location={props.location}
      showHeader={false}
      imageData={backgroundFluidImageStack}
      title={pageData.pageTitle}
    >
      <SEO title={pageData.sectionTitle} />

      <div className="container mx-auto px-4">
        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="hidden md:block md:w-1/2 px-4">
              <GalleryImage imageSrc={gallerie05h} />
            </div>
            <div className="md:w-1/2 h-auto px-4">
              <div className="mb-8">
                <StaticImage
                  className="rounded-sm shadow-md"
                  src={gallerie01}
                  alt="Café Milch und Zucker am Nord-Ostsee-Kanal direkt am Paradeplatz"
                />
              </div>
              <div>
                <StaticImage
                  className="rounded-sm shadow-md"
                  src={gallerie12}
                  alt="Hmm, lecker Schokokuchen"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie18}
                alt="Apfelkuchen mit Streusel und Sahne"
              />
            </div>
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie22}
                alt="Cappuccino im Freien"
              />
            </div>
          </div>
        </section>

        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie03}
                alt="Leckere Getränke"
              />
            </div>
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie04}
                alt="Cup Cakes und Muffins"
              />
            </div>
          </div>
        </section>

        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie10}
                alt="Rhabarberkuchen mit Baiser"
              />
            </div>
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie19}
                alt="Herzlich willkommen sind Alt&Jung"
              />
            </div>
          </div>
        </section>

        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie05}
                alt="Rhabarberkuchen mit Baiser"
              />
            </div>
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie07}
                alt="Herzlich willkommen sind Alt&Jung"
              />
            </div>
          </div>
        </section>

        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie09}
                alt="Rhabarberkuchen mit Baiser"
              />
            </div>
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie13}
                alt="Herzlich willkommen sind Alt&Jung"
              />
            </div>
          </div>
        </section>

        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie14}
                alt="Rhabarberkuchen mit Baiser"
              />
            </div>
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie16}
                alt="Herzlich willkommen sind Alt&Jung"
              />
            </div>
          </div>
        </section>

        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie17}
                alt="Rhabarberkuchen mit Baiser"
              />
            </div>
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie15}
                alt="Herzlich willkommen sind Alt&Jung"
              />
            </div>
          </div>
        </section>

        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie26}
                alt="Rhabarberkuchen mit Baiser"
              />
            </div>
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie27}
                alt="Herzlich willkommen sind Alt&Jung"
              />
            </div>
          </div>
        </section>

        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie21}
                alt="Rhabarberkuchen mit Baiser"
              />
            </div>
            <div className="md:w-1/2 px-4 mb-8 md:mb-0">
              <StaticImage
                className="rounded-sm shadow-md"
                src={gallerie23}
                alt="Herzlich willkommen sind Alt&Jung"
              />
            </div>
          </div>
        </section>

        {/* <section className="pt-8 px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="md:w-1/3 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1529022805552-1c88a713c1c5?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/3 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1493857671505-72967e2e2760?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/3 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1494346480775-936a9f0d0877?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDF8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/3 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1498038524759-281e0dd3ac15?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTl8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/3 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1493857671505-72967e2e2760?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/3 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1493857671505-72967e2e2760?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
          </div>
        </section>
        <section className="py-8 px-4">
          <div className="flex flex-wrap -mx-4 -mb-8">
            <div className="md:w-1/4 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1564486054184-1c26aa52b1c3?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzN8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/4 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1603020500697-1bf30af0d368?ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODF8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/4 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1583447059716-4541637d0256?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTh8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/4 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1583447059716-4541637d0256?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTh8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/4 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1583447059716-4541637d0256?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTh8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/4 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1583447059716-4541637d0256?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTh8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/4 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1583447059716-4541637d0256?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTh8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
            <div className="md:w-1/4 px-4 mb-8">
              <StaticImage                 className="rounded-sm shadow-md"
                src="https://images.unsplash.com/photo-1583447059716-4541637d0256?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTh8fGNhZmV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
                alt
              />
            </div>
          </div>
        </section> */}
      </div>
    </Layout>
  )
}

export default IndexPage
