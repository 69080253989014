import { useStaticQuery, graphql } from "gatsby"

export const useGalleryImage = () => {
  const { galleryImage } = useStaticQuery(
    graphql`
      query GalleryImage {
        galleryImage: file(relativePath: { eq: "newset01/DSC03322.png" }) {
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
  return galleryImage
}
